import { Suspense, lazy } from 'react'
import { Analytics } from '@vercel/analytics/react'
import { LoadingAnimation } from './components/LoadingAnimation'
import { Navigation } from './components/Navigation'
const Hero = lazy(() => import('./components/Hero'))

function App() {
  return (
    <Suspense fallback={<LoadingAnimation />}>
      <Navigation />
      <Hero />
      <Analytics />
    </Suspense>
  )
}

export default App