import React, { useState } from 'react';
import { Icon } from '@iconify/react';

export const Navigation: React.FC = () => {
  return (
    <nav className="nav-container">
      <div className="container">
        <div className="nav-content">
          <div className="nav-brand">
            <a href="/" className="nav-logo">
              <Icon icon="gis:story-maps" className="nav-logo-icon" />
            </a>
          </div>
          
          {/* Always visible navigation links */}
          <div className="nav-links">
            <NavLinks />
          </div>
        </div>
      </div>
    </nav>
  );
};

const NavLinks = () => (
  <>
    <a href="/experiences" className="nav-link">
      <Icon icon="streamline:vr-headset-2" className="nav-link-icon" />
    </a>
    <a href="/studio" className="nav-link">
      <Icon icon="mdi:company" className="nav-link-icon" />
    </a>
    <a href="/merch" className="nav-link">
      <Icon icon="mdi:shopping" className="nav-link-icon" />
    </a>
    <a href="/blog" className="nav-link">
      <Icon icon="mdi:post-outline" className="nav-link-icon" />
    </a>
  </>
);