import { motion } from 'framer-motion'

export const LoadingAnimation = () => {
  return (
    <div className="fullscreen-overlay">
      <div className="loading-container">
        {[...Array(7)].map((_, index) => (
          <motion.div key={index} className="line-container">
            <motion.div
              className="line"
              initial={{ width: "0%" }}
              animate={{ 
                width: "100%",
                transition: {
                  duration: 2,
                  ease: "easeInOut",
                  repeat: Infinity,
                  delay: index * 0.2 // Stagger the start of each line
                }
              }}
            />
          </motion.div>
        ))}
        
        <motion.div 
          className="loading-text-container"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
        >
          <motion.span
            className="loading-text"
            animate={{ opacity: [1, 0.5, 1] }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          >
            Loading...
          </motion.span>
        </motion.div>
      </div>
    </div>
  )
} 